import { createBrowserRouter } from "react-router-dom";
import { RequireAuth } from "../utils/RequireAuth";
import SignIn from "../pages/sign-in";
import RhForm from "../pages/rh-form";
import List from "../pages/list";
import ListForms from "../pages/list-forms";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        path: "formulario",
        element: (
          <RequireAuth>
            <RhForm />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        path: "lista",
        element: (
          <RequireAuth>
            <List />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "/",
    children: [
      {
        path: "caso",
        element: (
          <RequireAuth>
            <ListForms />
          </RequireAuth>
        ),
      },
    ],
  },
]);

export default router;
