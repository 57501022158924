import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import { format } from 'date-fns';
import { TmpButton } from "@tempo/tempo-design-system-core/dist";
import { DateCard, DateContainer, RangeButton, CalendarOptions, DateCalendars, ErrorMessage } from './styles';
import { CALENDAR_LOCALE, DATE_FORMAT } from './constants';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';

const DateRange = (props) => {
  const currentDate = new Date();

  const { label, helperText, onConfirm } = props;

  const [opened, setOpened] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);

  const handleOpenCard = () => {
    setOpened(!opened);
  }

  const handlerChangeStart = (date) => {
    setStartDate(date);
  }

  const handlerChangeEnd = (date) => {
    setEndDate(date);
  }

  const handleConfirm = () => {
    setOpened(!opened);
    if (onConfirm) onConfirm({ startDate, endDate });
  }

  const handleCancel = () => {
    setOpened(false);
  }

  return (
    <DateContainer error={!!props.error}>
      {label && (<label>{label}</label>)}

      <RangeButton error={!!props.error} onClick={handleOpenCard}>
        {`${format(startDate, DATE_FORMAT)} - ${format(endDate, DATE_FORMAT)}`}
      </RangeButton>

      {helperText && <span>{helperText}</span>}
      {!!props.error && <ErrorMessage>{props.error}</ErrorMessage>}

      {opened && (
        <DateCard>
          <DateCalendars>
            <Calendar
              value={startDate}
              locale={CALENDAR_LOCALE}
              onChange={handlerChangeStart}
            />

            <Calendar
              value={endDate}
              locale={CALENDAR_LOCALE}
              onChange={handlerChangeEnd}
            />
          </DateCalendars>

          <CalendarOptions>
            <TmpButton handleClick={handleCancel}>
              Cancelar
            </TmpButton>

            <TmpButton handleClick={handleConfirm}>
              Confirmar
            </TmpButton>
          </CalendarOptions>
        </DateCard>
      )}
    </DateContainer>
  )
}

DateRange.propTypes = {
  value: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

DateRange.defaultProps = {
  placeholder: 'dd/mm/aaaa - dd/mm/aaaa',
}

export { DateRange }
