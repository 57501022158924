import { contributorClient } from '../services/contributor-client';

export async function searchContributor(enrollment) {
  try {
    const response = await contributorClient().get(
      `${process.env.REACT_APP_CONTRIBUTOR_URL}/${enrollment}`
    );

    return response.data;
  } catch {
    return null;
  }
}
