import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData from '../../assets/animations/loading.json';

const Loading = (props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      width={props.width}
      height={props.height}
    />
  )
}

Loading.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

Loading.defaultProps = {
  width: 200,
  height: 200,
}

export { Loading }
