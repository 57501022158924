import React, { useState, useEffect, useCallback } from "react";
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from "react-router-dom";
import Lottie from 'react-lottie';
import animationData from '../../assets/animations/hr-manager.json';
import { useAuth } from "../../contexts/AuthContext";
import {
  ContainerLogin,
  Content,
  Main,
  Header,
  Title,
  LoginFields,
  ButtonLogin,
  Footer,
  LinksFooter,
  Picture,
  HRModalWrapper
} from "./style";
import { TmpInput, TmpButton, TmpModal } from "@tempo/tempo-design-system-core/dist";
import { Loading } from '../../components/Loading';
import LogoRAVI from "../../assets/logo_ravi.svg";
import PictureLogin from "../../assets/picture_login.svg";

const SignIn = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const {
    user,
    openHrModal,
    isLoading,
    handleSignIn,
    hasError,
    loginError,
    handleCloseModal,
  } = useAuth();

  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [recaptcha, setRecaptcha] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();
    handleSignIn({ username, password, recaptcha });
  }

  const handlerRecaptchaChange = useCallback((value) => {
    console.log(value)
    setRecaptcha(value)
  }, [])

  useEffect(() => {
    if (user) navigate('/lista');
  }, [user, navigate]);

  return (
    <ContainerLogin>
      <Content>
        <Main>
          <Header>
            <img src={LogoRAVI} alt="Logo RAVI" />
            <h1>RAVI - Chamados RH Gestor</h1>
          </Header>
          <Title>
            <h2>
              Bem-vindo ao <br />
              RAVI - Chamados RH Gestor
            </h2>
          </Title>
          <LoginFields onSubmit={handleSubmit}>
            <TmpInput
              required
              error={hasError}
              label={'Usuário de rede'}
              maxLength={50}
              id="username"
              onChange={(e) => setUsername(e.target.value)}
            />
            <TmpInput
              required
              error={hasError}
              label={'Senha'}
              type="password"
              maxLength={50}
              id="password"
              helperText={loginError ? loginError : ""}
              onChange={(e) => setPassword(e.target.value)}
            />

            <TmpModal
              isOpened={openHrModal}
              handleClose={handleCloseModal}
              labelPrimary="Certo"
            >
              <HRModalWrapper>
                <Lottie
                  options={defaultOptions}
                  width={350}
                  height={350}
                />

                <h3>Por favor, procure o RH para liberar sua conta</h3>
              </HRModalWrapper>
            </TmpModal>

            <GoogleReCaptcha onVerify={handlerRecaptchaChange} />

            <ButtonLogin>
              {isLoading
                ? (<Loading height={150} width={150} />)
                : (
                  <>
                    <TmpButton type="primary" size="sm" handleClick={false}>
                      Entrar
                    </TmpButton>
                  </>
                ) }
            </ButtonLogin>
          </LoginFields>
        </Main>
        <Footer>
          <h3>Dúvidas sobre o Ravi - Chamados RH Gestor?</h3>
          <LinksFooter>
            <a
              href="https://paciente.hsl.org.br/ajuda-suporte"
              target="_blank"
              rel="noreferrer"
            >
              Ajuda e Suporte
            </a>
            <a
              href="https://paciente.hsl.org.br/termos"
              target="_blank"
              rel="noreferrer"
            >
              Termos de uso
            </a>
            <a
              href="https://paciente.hsl.org.br/privacidade"
              target="_blank"
              rel="noreferrer"
            >
              Política de privacidade
            </a>
          </LinksFooter>
        </Footer>
      </Content>
      <Picture style={{ backgroundImage: `url(${PictureLogin})` }} />
    </ContainerLogin>
  );
};

export default SignIn;
