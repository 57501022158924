import styled from 'styled-components';

export const PulseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffff;
  background: #2C6EF2;
  border: none;
  border-radius: 50%;
  padding: 14px;
  font-weight: bold;

  width: 32px;
  height: 32px;

  transition: background-color 0.2s ease-in;
  transition: color 0.2s ease-in;

  &:hover {
    color: #2C6EF2;
    background: #C5D7FB;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  animation: pulse 1s infinite;
`;

export const InfoCart = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  z-index: 2;
  top: 1340px;
  left: 420px;

  background: #ffff;
  min-width: 520px;
  min-height: 120px;
  border-radius: 8px;
  padding: 13px 18px;

  -webkit-box-shadow: -1px 1px 10px 0px rgba(0,0,0,0.37);
  box-shadow: -1px 1px 10px 0px rgba(0,0,0,0.37);
`;
