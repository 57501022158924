import React, { useState } from 'react';
import { TmpUploader } from '@tempo/tempo-design-system-core/dist';
import PropTypes from 'prop-types';
import { UploaderWrapper } from './styles'
import { FileList } from '../FileList';
import { fileBase64 } from '../../utils/file-base64';

const Uploader = (props) => {
  const { onChange, helperText } = props;

  const [fileList, setFileList] = useState([]);
  const [maxSizeError, setMaxSizeError] = useState('');

  const handleChangeFile = async (files) => {
    const internalFiles = [...fileList];
    const maxSize = 9;
    let totalFilesSize = 0;

    for (const file of files) {
      const sizeInMb = file.size / (1024 * 1024);

      if (sizeInMb > maxSize) {
        setMaxSizeError(`O arquivo tamanho do arquivo '${file.name}' não pode ser superior a ${maxSize}mb`);
        return
      }

      totalFilesSize += sizeInMb;

      if (totalFilesSize > maxSize) {
        setMaxSizeError(`O total de arquivos não pode ultrapassar ${maxSize}mb`);
        return
      }

      const base64 = await fileBase64(file);
      internalFiles.push({ name: file.name, base64 });
      setMaxSizeError('')
    }

    setFileList(internalFiles);
    onChange(internalFiles);
  }

  const handleCancelFile = (item) => {
    const removeCanceledFile = fileList.filter(
      file => file.name !== item.name
    );

    setFileList(removeCanceledFile);
  }

  return (
    <UploaderWrapper>
      <TmpUploader
        helperText={helperText}
        handleChange={handleChangeFile}
      />

      {!!maxSizeError && (<small>{maxSizeError}</small>)}

      <FileList files={fileList} onCancelFile={handleCancelFile} />
    </UploaderWrapper>
  )
}

Uploader.propTypes = {
  helperText: PropTypes.string,
  onChange: PropTypes.func,
}

Uploader.defaultProps = {
  helperText: 'PNG, JPG, PDF, XLS, XLSX, DOC e DOCX',
}

export { Uploader }
