import { useState, useEffect } from 'react';
import { taskClient } from '../services/task-client';
import { useAuth } from '../contexts/AuthContext';

export const useFetchTasks = (
  { page = 1, perPage = 5, status = null }
) => {
  const { user } = useAuth();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const urlParams = {
      numero: perPage,
      pagina: page,
    }

    if (status) urlParams['status'] = status;

    const params = new URLSearchParams(urlParams).toString();

    const url = `${process.env.REACT_APP_TASKS_URL}/${user.email}?${params}`;

    taskClient().get(url)
      .then(response => {
        response.data.forEach((task, index) => {
          response.data[index].ultima_modificacao = new Date(
            task.ultima_modificacao
          ).toLocaleString({ language: 'pt-br' });
        });

        setData([...response.data])
      })
      .finally(() => setIsLoading(false));
  }, [page, perPage, status, user]);

  return { data, isLoading }
}
