import axios from 'axios';

export function taskClient() {
  let accessToken = undefined;
  const user = localStorage.getItem('user');

  if (user) {
    const userData = JSON.parse(user);
    accessToken = userData.accessToken;
  }

  const headers = { Authorization: `Bearer ${accessToken}` };

  return axios.create({ headers });
}
