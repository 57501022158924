import { useState, useEffect } from 'react';
import { taskClient } from '../services/task-client';

export const useFetchTaskDetails = (taskId, reopened = false) => {

  const [data, setData] = useState({ numero_caso: '' });
  const [statusName, setStatusName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    taskClient().get(`${process.env.REACT_APP_TASKS_URL}/${taskId}`)
      .then(response => {
        setIsLoading(true);

        if (!response.data.assunto) {
          setData({ ...data, assunto: 'Sem assunto'});
        }

        if (!response.data.conclusao) {
          setData({ ...data, assunto: 'Sem conclusão' });
        }

        if (response.data.status === "Em andamento") {
          setStatusName('status-em-andamento');
        }

        if (response.data.status === "Novo") {
          setStatusName('status-novo');
        }

        response.data.ultima_modificacao = new Date(
          response.data.ultima_modificacao,
        ).toLocaleString({ language: 'pt-br' });

        setData({ ...data, ...response.data });
      })
      .finally(() => setIsLoading(false));
  }, [taskId, reopened]);

  return { data, statusName, isLoading }
}
