import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    overflow-x: hidden;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  button {
    cursor: pointer;
  }
`
