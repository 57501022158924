import React from 'react';
import PropTypes from 'prop-types';
import { FiTrash2 } from 'react-icons/fi';
import Arquivo from '@tempo/tempo-assets/dist/react/icons/arquivo';
import { ListWrapper, FileItem } from './styles';

const FileList = (props) => {
  const { files, onCancelFile } = props;

  if (files.length === 0) return null;

  return (
    <ListWrapper>
      {files.map((file, index) => (
        <FileItem key={index}>
          <div>
            <Arquivo />
            <b>{file.name}</b>
          </div>

          <button type="button" onClick={() => onCancelFile(file)}>
            <FiTrash2 size={18} />
          </button>
        </FileItem>
      ))}
    </ListWrapper>
  )
}

FileList.propTypes = {
  files: PropTypes.array.isRequired,
  onCancelFile: PropTypes.func,
}

export { FileList }
