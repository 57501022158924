import styled from "styled-components";

export const Container = styled.div`
  background-color: #f5f6fd;
`;

export const BrandingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(-170px + 24px);
  gap: 48px;

  img {
    height: auto;
    :first-child {
      width: 3rem;
      @media (max-width: 648px) {
        width: 9.063rem;
        margin-left: 24px;
      }
    }
  }

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: -0.01em;
    color: #0a3a99;
    margin-right: auto;
  }
`;

export const Profile = styled.div`
  margin: 20px 32px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 23.5px 32px 23.5px 48px;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
  }

  #button-close {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

export const Form = styled.div`
  background-color: #ffffff;
  width: 94%;
  margin: auto;
  margin-top: 56px;
  margin-bottom: 56px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    padding: 44px 0 2.72px 3.6%;
  }
`;

export const TitleForm = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;

export const Content = styled.div`
  padding: 40px 56px 0;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 5px;
  }
`;

export const Select = styled.div`
  margin: 25px 0;

  button {
    width: 100%;
  }
`;

export const ButtonForm = styled.div`
  display: flex;
  justify-content: end;
  margin: 40px 0 0;
  padding-bottom: 28px;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: "Inter";
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 19px 0;
  margin: 0;
  background-color: white;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #666666;
    margin: 0;
  }
`;

export const VacationDates = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
`;

export const VacationSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;

  small {
    color: #828282;
    margin-left: 14px;
    margin-top: 7px;
  }
`;

export const VacationSwitch = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  margin-top: 14px;

  label {
    & + label {
      margin-left: 15px;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CollaboratorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 14px;

  button {
    margin: 14px 14px 0;
  }
`;
