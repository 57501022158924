import { ThemeProvider } from "@tempo/tempo-design-system-core/dist/theme";
import { RouterProvider } from "react-router-dom";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { AuthProvider } from "./contexts/AuthContext";
import router from "./routes";

export default function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
          <AuthProvider>
      <ThemeProvider brand="hsl" theme="default">
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthProvider>
    </GoogleReCaptchaProvider>
  );
}
