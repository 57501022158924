import React, { useEffect } from 'react';
import { useLocation, Navigate, redirect } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { healthCheck } from '../api/health-check';

export function RequireAuth({ children }) {
  const { user, handleLogOut } = useAuth();
  const location = useLocation();

  useEffect(() => {
    healthCheck()
      .catch(() => {
        handleLogOut()
        redirect('/login')
      })
  }, [])

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    return children;
  }
}
