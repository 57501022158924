export function dataMapper(map, data) {
  const newData = {};
  const mapEntries = Object.entries(map);

  for (const [key, field] of mapEntries) {
    newData[field] = data[key];
    delete data[key];
  }

  return { ...newData, ...data };
}
