import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import {
  Container,
  BrandingWrapper,
  Profile,
  ContentModal,
  Title,
  Table,
  IconButtonTable,
  IconButton,
  Footer,
  EmptyTasksCard,
  HeaderActions,
} from "./style";
import {
  TmpHeaderBase,
  DropdownProfile,
  TmpModal,
  TmpButton,
  TmpFilter,
} from "@tempo/tempo-design-system-core/dist";
import LogOut from "@tempo/tempo-assets/dist/react/icons/sair";
import SetaDireita from "@tempo/tempo-assets/dist/react/icons/seta_direita";
import SetaEsquerda from "@tempo/tempo-assets/dist/react/icons/seta_esquerda";
import Arquivo from "@tempo/tempo-assets/dist/react/icons/arquivo";
import LogoRAVI from "../../assets/logo_ravi.svg";
import WarningIcon from "../../assets/warning_icon.svg";
import { Loading } from '../../components/Loading';
import { useFetchTasks } from '../../api/tasks';

const List = () => {
  const navigate = useNavigate();
  const { user, handleLogOut } = useAuth();

  const [statusList] = useState([
    {
      label: "Novo",
    },
    {
      label: "Em andamento",
    },
    {
      label: "Transferido N2",
    },
    {
      label: 'Finalizado',
    },
    {
      label: "Fechado",
    },
  ]);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isOpenedLogOut, setIsOpenedLogOut] = useState(false);
  const [pagina, setPagina] = useState(1);

  const username = user.nome;

  const getInitials = (name) => {
    if (name && name?.split(" ")?.length > 0) {
      return name
        ?.split(" ")
        ?.map((value) => value?.charAt(0))
        ?.join("")
        ?.substr(0, 2)
        ?.toUpperCase();
    }
    return name;
  };

  const { data: tasks, isLoading } = useFetchTasks({ page: pagina, status: selectedStatus });

  const handleApplyFilter = (items) => {
    const statusFilter = Array.from(items)
      .filter(item => item.selected)
      .map(item => item.label);

    setSelectedStatus(statusFilter.join(','));
  }

  const handleCancelStatusFilter = () => {
    setSelectedStatus(null);
  }

  function handleSubmit() {
    navigate("/formulario");
  }

  function ano() {
    return new Date().getFullYear();
  }

  function redirectCase(e) {
    navigate(`/caso?meta-id=${e.target.getAttribute("meta-id")}`);
  }

  async function avancarPagina() {
    if (tasks.length !== 0) {
      let proximaPagina = pagina + 1;
      setPagina(proximaPagina);
    }
  }

  async function retrocederPagina() {
    if (pagina > 1) {
      let paginaAnterior = pagina - 1;
      setPagina(paginaAnterior);
    }
  }

  return (
    <Container>
      <TmpHeaderBase
        children={
          <BrandingWrapper>
            <img src={LogoRAVI} alt="Logo RAVI"></img>
            <h1>RAVI - Chamados RH - Gestor</h1>
            <Profile>
              <DropdownProfile
                name={user.nome}
                account="Conta principal"
                avatarInitials={getInitials(username)}
                listItems={[
                  {
                    label: "Sair da minha conta",
                    leadingIcon: <LogOut />,
                    handleClick: () => setIsOpenedLogOut(true),
                  },
                ]}
              ></DropdownProfile>
              <TmpModal
                isOpened={isOpenedLogOut}
                isDoubleAction={true}
                labelPrimary="Sim"
                labelSecondary="Não"
                handleConfirm={handleLogOut}
                handleCancel={(e) => setIsOpenedLogOut(false)}
                handleClose={(e) => setIsOpenedLogOut(false)}
              >
                <ContentModal>
                  <img src={WarningIcon} alt="Ícone de aviso" />
                  <p>Deseja sair da sua conta?</p>
                </ContentModal>
              </TmpModal>
            </Profile>
          </BrandingWrapper>
        }
      />

      <Title>
        <h1>Minhas Manifestações</h1>
      </Title>

      <HeaderActions>
        <TmpFilter
          label="Meus casos"
          type="checkbox"
          filterItems={statusList}
          handleClickPrimary={handleApplyFilter}
          handleClickSecondary={handleCancelStatusFilter}
        />

        <TmpButton type="primary" size="sm" handleClick={handleSubmit}>
          <Arquivo /> Nova manifestação
        </TmpButton>
      </HeaderActions>

      {isLoading || tasks.length === 0
        ? (
          <EmptyTasksCard>
            {isLoading ? (<Loading width={100} height={100} />) : (<h2>Nenhum Registro encontrado</h2>)}
          </EmptyTasksCard>
        )
        : (
          <Table>
            <table>
              <thead>
                <tr>
                  <td className="header-table" id="h1">
                    Número do Caso
                  </td>
                  <td className="header-table" id="h2">
                    Assunto
                  </td>
                  <td className="header-table" id="h3">
                    Status
                  </td>
                  <td className="header-table" id="h4">
                    Data/hora da modificação
                  </td>
                  <td className="header-table" id="h5">
                    Conclusão
                  </td>
                </tr>
              </thead>

              <tbody>
                {tasks.map((chamado) => {
                  const statusNames = {
                    "Novo": "status-novo",
                    "Em Andamento": "status-em-andamento",
                    "Transferido N2": "status-warn",
                    "Finalizado": "status-close",
                    "Fechado": "status-close",
                  }

                  const statusName = statusNames[chamado.status]

                  if (chamado.assunto === null) {
                    chamado.assunto = "Sem assunto";
                  }

                  if (chamado.conclusao === null) {
                    chamado.conclusao = "Sem conclusão";
                  }

                  return (
                    <tr key={chamado.id}>
                      <td className="link-table">
                        <h1
                          className="redirect"
                          meta-id={chamado.id}
                          onClick={(e) => redirectCase(e)}
                        >
                          {chamado.numero_caso}
                        </h1>
                      </td>
                      <td className="link-table">
                        <h1
                          className="redirect"
                          meta-id={chamado.id}
                          onClick={(e) => redirectCase(e)}
                        >
                          {chamado.assunto}
                        </h1>
                      </td>
                      <td>
                        <p className={statusName || "status-close"}>{chamado.status}</p>
                      </td>
                      <td>{chamado.ultima_modificacao}</td>
                      <td>{chamado.conclusao}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <p>
          Avançar<a onClick={(e) => avancarPagina()}>Seta</a>
        </p>
        <p>
          Retroceder<a onClick={(e) => retrocederPagina()}>Seta</a>
        </p> */}
          </Table>
        )
      }
      <IconButtonTable>
        <IconButton>
          <SetaEsquerda onClick={retrocederPagina} />
        </IconButton>

        <b>Página {pagina}</b>

        <IconButton>
          <SetaDireita onClick={avancarPagina} />
        </IconButton>
      </IconButtonTable>
      <Footer>
        <p>© {ano()} Hospital Sírio-Libanês</p>
      </Footer>
    </Container>
  );
};

export default List;
