import styled from "styled-components";

export const Container = styled.div`
  background-color: #f5f6fd;
`;

export const BrandingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(-170px + 24px);
  gap: 48px;

  img {
    height: auto;
    :first-child {
      width: 3rem;
      @media (max-width: 648px) {
        width: 9.063rem;
        margin-left: 24px;
      }
    }
  }

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: -0.01em;
    color: #0a3a99;
    margin-right: auto;
  }
`;

export const Profile = styled.div`
  margin: 20px 32px;
`;

export const ContentModal = styled.div`
  margin-top: 130px;

  img {
    display: flex;
    margin: auto;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 23.5px 32px 23.5px 48px;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
  }
`;

export const ButtonNewManifestation = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 32px;
  margin-top: 32px;

  svg {
    font-size: 24px;
    margin-right: 11px;
  }
`;

export const FilterManifestation = styled.div`
  margin-left: 58px;
`;

export const TableList = styled.div`
  margin: 32px 32px 0;
  padding-bottom: 144px;
`;

export const EmptyTasksCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: #3e3e3e;
  background: #ffffff;
  width: 94%;
  padding: 10px;
  border-radius: 10px;
  border-collapse: collapse;
  margin: 32px auto 50px;
`;

export const Table = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px auto 50px;

  table {
    width: 94%;
    padding: 10px;
    background: #ffffff;
    border-radius: 10px;
    border-collapse: collapse;
  }

  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 20px 54px;
    margin: 0;
  }

  tr:nth-child(even) {
    background: rgba(245, 245, 245, 0.32);
  }

  .header-table {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #292929;
  }

  .redirect {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2c6ef2;
    text-decoration: none;
    cursor: pointer;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #ea1f1f;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #ea1f1f;
    border-radius: 4px;
    padding: 9px 16px;
  }

  .status-em-andamento {
    color: #3aa141;
    border-color: #3aa141;
  }

  .status-warn {
    color: #ff6e26;
    border-color: #ff6e26;
  }

  .status-close {
    color: #ea1f1f;
    border-color: #ea1f1f;
  }

  .status-novo {
    color: #2c6ef2;
    border-color: #2c6ef2;
  }
`;

export const IconButtonTable = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 70px;
`;

export const IconButton = styled.div`
  cursor: pointer;
  font-size: 24px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 19px 0;
  margin: 0;
  background-color: white;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #666666;
    margin: 0;
  }
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 25px 55px;
`;
