import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PulseButton, InfoCart } from './styles';

const InfoButton = (props) => {
  const [opened, setOpened] = useState(false);

  const handlerOpenCard = () => {
    setOpened(!opened);
  }

  return (
    <>
      <PulseButton onClick={handlerOpenCard}>
        {'?'}
      </PulseButton>

      {opened && (
        <InfoCart>
          <p>{props.text}</p>
        </InfoCart>
      )}
    </>
  )
}

InfoButton.propTypes = {
  text: PropTypes.string.isRequired,
}

export { InfoButton }
