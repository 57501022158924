import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

const AnimationIcon = (props) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: props.animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      width={props.width}
      height={props.height}
    />
  )
}

AnimationIcon.propTypes = {
  animationData: PropTypes.object.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
}

AnimationIcon.defaultProps = {
  width: 200,
  height: 200,
}

export { AnimationIcon }

