import styled from 'styled-components'

export const UploaderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  small {
    color: #fc033d;
    font-weight: bold;
    margin-top: 5px;
  }
`
