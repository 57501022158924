import styled from "styled-components";

export const ContainerLogin = styled.div`
  display: grid;
  grid-template-columns: 1fr 640px;
  height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  max-width: 424px;
  width: 100%;

  img {
    width: 56px;
    height: 70.37px;
  }

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: -0.01em;
    color: #0a3a99;
    margin-left: 28px;
    width: 160px;
    display: flex;
    align-items: end;
  }
`;

export const Title = styled.div`
  margin-bottom: 36px;

  h2 {
    width: 424px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #221f20;
  }
`;

export const LoginFields = styled.form`
  max-width: 424px;
  width: 100%;
  margin-bottom: 24px;
`;

export const ButtonLogin = styled.div`
  margin-top: 32px;
`;

export const Footer = styled.div`
  background-color: #f4f5f7;
  padding: 32px 16px;

  h3 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #221f20;
  }
`;

export const LinksFooter = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #0061af;
    text-decoration: none;
  }
`;

export const Picture = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
`;

export const HRModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 34px;
    text-align: center;
  }
`;
