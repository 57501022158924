import styled from "styled-components";

export const Container = styled.div`
  background-color: #f5f6fd;
`;

export const BrandingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: calc(-170px + 24px);
  gap: 48px;

  img {
    height: auto;
    :first-child {
      width: 3rem;
      @media (max-width: 648px) {
        width: 9.063rem;
        margin-left: 24px;
      }
    }
  }

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: -0.01em;
    color: #0a3a99;
    margin-right: auto;
  }
`;

export const Profile = styled.div`
  margin: 20px 32px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 0 32px 0 48px;
  border-bottom: 1px solid #e0e0e0;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: -0.01em;
    color: #292929;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2c6ef2;
    margin-left: 5px;
  }

  #button-close {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

export const Table = styled.div`
  display: flex;
  justify-content: center;
  margin: 56px auto 16px;

  table {
    width: 94%;
    padding: 10px;
    background: #ffffff;
    border-radius: 10px;
    border-collapse: collapse;
  }

  td {
    border-bottom: 1px solid #e0e0e0;
    padding: 20px 54px;
    margin: 0;
  }

  tr:nth-child(even) {
    background: rgba(245, 245, 245, 0.32);
  }

  .header-table {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #292929;
  }

  #h1 {
    width: 113px;
  }

  #h2 {
    width: 422px;
  }

  #h3,
  #h4 {
    width: 211px;
  }

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2c6ef2;
    text-decoration: none;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #ea1f1f;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #ea1f1f;
    border-radius: 4px;
    padding: 9px 16px;
  }

  .status-em-andamento {
    color: #3aa141;
    border-color: #3aa141;
  }

  .status-novo {
    color: #2c6ef2;
    border-color: #2c6ef2;
  }
`;

export const Case = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentModal = styled.div`
  margin-top: 130px;

  img {
    display: flex;
    margin: auto;
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #000000;
  }
`;

export const Form = styled.div`
  background-color: #ffffff;
  width: 94%;
  margin: auto;
  margin-bottom: 56px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    padding: 44px 0 2.72px 3.6%;
  }
`;

export const TitleForm = styled.div`
  border-bottom: 1px solid #e0e0e0;
`;

export const Content = styled.div`
  padding: 40px 56px 0;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 5px;
  }
`;

export const Select = styled.div`
  margin: 25px 0;

  button {
    width: 100%;
  }
`;

export const ContainerFerias = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

export const ButtonForm = styled.div`
  display: flex;
  justify-content: end;
  margin: 40px 0 0;
  padding-bottom: 28px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 19px 0;
  margin: 0;
  background-color: white;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #666666;
    margin: 0;
  }
`;
