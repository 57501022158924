import styled from 'styled-components';

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffff;
  margin-bottom: 7px;

  label {
    color: ${props => props.error ? 'rgb(233, 53, 53)' : '#292929'};
    margin-bottom: 5px;
  }
`;

export const RangeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  border: none;
  border: 1px solid ${props => props.error ? 'rgb(233, 53, 53)' : '#292929'};
  font-size: 16px;
  /* font-weight: bold; */
  min-width: 312px;
  height: 56px;
  padding: 0 16px;
  border-radius: 8px;
`;

export const DateCard = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;

  background: #ffff;
  min-width: 520px;
  min-height: 320px;
  border-radius: 8px;
  padding: 13px 18px;

  -webkit-box-shadow: -1px 1px 10px 0px rgba(0,0,0,0.37);
  box-shadow: -1px 1px 10px 0px rgba(0,0,0,0.37);
`;

export const DateCalendars = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  .react-calendar {
    & + .react-calendar {
      margin-left: 15px;
    }
  }
`;

export const CalendarOptions = styled.div`
  display: flex;
  flex-direction: row;

  button {
    width: 100%;

    & + button {
      margin-left: 15px;
    }
  }
`;

export const ErrorMessage = styled.span`
  color: rgb(233, 53, 53);
`
