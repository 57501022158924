import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext, useAuth } from "../../contexts/AuthContext";
import {
  BrandingWrapper,
  Profile,
  Container,
  Title,
  Table,
  Case,
  ContentModal,
  Form,
  TitleForm,
  Content,
  Select,
  ContainerFerias,
  ButtonForm,
  Footer,
} from "./style";
import {
  TmpHeaderBase,
  DropdownProfile,
  TmpModal,
  TmpInput,
  TmpSelect,
  TmpSwitch,
  TmpTextarea,
  TmpButton,
} from "@tempo/tempo-design-system-core/dist";
import LogOut from "@tempo/tempo-assets/dist/react/icons/sair";
import { Loading } from '../../components/Loading';
import LogoRAVI from "../../assets/logo_ravi.svg";
import CloseButton from "../../assets/close_button.svg";
import WarningIcon from "../../assets/warning_icon.svg";
import CheckIcon from "../../assets/check_icon.svg";
import { reOpenTask } from '../../api/reopen-task';
import { useFetchTaskDetails } from "../../api/task-detail";

const ListForms = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { handleLogOut } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [values, setValues] = useState({});
  const [checked, setChecked] = useState(false);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isOpenedTwo, setIsOpenedTwo] = useState(false);
  const [isOpenedLogOut, setIsOpenedLogOut] = useState(false);
  const [isReOpened, setIsReOpened] = useState(false);
  const [idCaso, setIdCaso] = useState(searchParams.get("meta-id"));
  // const [chamado, setChamado] = useState({ numero_caso: "" });
  const [mensagemRequisicao, setMensagemRequisicao] =
    useState("Processando...");
  const [iconRequisicao, setIconRequisicao] = useState();
  const username = user.nome;

  const getInitials = (name) => {

    if (name && name?.split(" ")?.length > 0) {
      return name
        ?.split(" ")
        ?.map((value) => value?.charAt(0))
        ?.join("")
        ?.substr(0, 2)
        ?.toUpperCase();
    }
    return name;
  };

  useEffect(() => {
    if (!idCaso) navigate('/lista');
  }, [idCaso, navigate]);

  const { data: chamado, statusName } = useFetchTaskDetails(
    idCaso,
    isReOpened,
  );

  function handleClose() {
    navigate("/lista");
  }

  function handleValueChange(e) {
    setValues({ ...values, [e.target.id]: e.target.value });
  }

  function handleSelectChange(e, name) {
    setValues({ ...values, [name]: e.label });
  }

  function handleCheckbox(e, setChecked) {
    setChecked(e.target.checked);
    setValues({ ...values, [e.target.name]: e.target.checked });
  }

  function modalSecundary() {
    setIsOpened(false);
    setIsOpenedTwo(true);
  }

  function ano() {
    return new Date().getFullYear();
  }

  async function reabrirCaso() {
    let motivoReabertura = prompt("Digite o motivo do chamado");

    if (motivoReabertura === null) {
      motivoReabertura = "Sem motivo";
    }

    try {
      await reOpenTask(idCaso, { motivo_reabertura: motivoReabertura, email: user.email });
      setIsReOpened(!isReOpened);
      setMensagemRequisicao("Chamado reaberto com sucesso.");
      setIconRequisicao(CheckIcon);
    } catch {
      setMensagemRequisicao(
        "Entrar em contato com o RAVI e Bot para reabertura de chamado!"
      );
      setIconRequisicao(WarningIcon);
    }
  }

  return (
    <Container>
      <TmpHeaderBase
        children={
          <BrandingWrapper>
            <img src={LogoRAVI} alt="Logo RAVI"></img>
            <h1>RAVI - Chamados RH - Gestor</h1>
            <Profile>
              <DropdownProfile
                name={user.nome}
                account="Conta principal"
                avatarInitials={getInitials(username)}
                listItems={[
                  {
                    label: "Sair da minha conta",
                    leadingIcon: <LogOut />,
                    handleClick: () => setIsOpenedLogOut(true),
                  },
                ]}
              ></DropdownProfile>
              <TmpModal
                isOpened={isOpenedLogOut}
                isDoubleAction={true}
                labelPrimary="Sim"
                labelSecondary="Não"
                handleConfirm={() => handleLogOut()}
                handleCancel={() => setIsOpenedLogOut(false)}
                handleClose={() => setIsOpenedLogOut(false)}
              >
                <ContentModal>
                  <img src={WarningIcon} alt="Ícone de aviso" />
                  <p>Deseja sair da sua conta?</p>
                </ContentModal>
              </TmpModal>
            </Profile>
          </BrandingWrapper>
        }
      />
      <Title>
        <Case>
          <h1>
            Número do Caso <p>{chamado.numero_caso}</p>
          </h1>

          {/* {chamado.status === 'Fechado' && (
            <TmpButton
              type="primary"
              size="sm"
              handleClick={(e) => setIsOpened(true)}
            >
              Reabrir caso
            </TmpButton>
          )} */}

          <TmpModal
            isOpened={isOpened}
            isDoubleAction={true}
            labelPrimary="Não"
            labelSecondary="Sim"
            handleConfirm={(e) => setIsOpened(false)}
            handleCancel={(e) => {
              modalSecundary();
              reabrirCaso();
            }}
            handleClose={(e) => setIsOpened(false)}
          >
            <ContentModal>
              <img src={WarningIcon} alt="Ícone de aviso" />
              <p>Tem certeza de que gostaria reabrir este chamado?</p>
            </ContentModal>
          </TmpModal>
          <TmpModal
            isOpened={isOpenedTwo}
            isDoubleAction={false}
            labelPrimary="Fechar"
            handleConfirm={(e) => setIsOpenedTwo(false)}
            handleCancel={(e) => setIsOpenedTwo(false)}
            handleClose={(e) => setIsOpenedTwo(false)}
          >
            <ContentModal>
              <img src={iconRequisicao} alt="Ícone informativo" />
              <p>{mensagemRequisicao}</p>
            </ContentModal>
          </TmpModal>
        </Case>
        <button id="button-close" onClick={handleClose}>
          <img src={CloseButton} alt="Fechar" />
        </button>
      </Title>
      <Table>
        <table>
          <tr>
            <td className="header-table" id="h1">
              Status
            </td>
            <td className="header-table" id="h2">
              Assunto
            </td>
            <td className="header-table" id="h3">
              Data/hora da modificação
            </td>
            <td className="header-table" id="h4">
              Conclusão
            </td>
          </tr>

          <tr>
            <td>
              <p className={statusName}>{chamado.status}</p>
            </td>
            <td className="link-table">
              <h1>{chamado.assunto}</h1>
            </td>
            <td>{chamado.ultima_modificacao}</td>
            <td>{chamado.conclusao}</td>
          </tr>
        </table>
      </Table>
      <Form>
        <TitleForm>
          <h2>Contato</h2>
        </TitleForm>
        <Content>
          <TmpInput
            disabled={true}
            id="name"
            handleChange={handleValueChange}
            label="*Nome"
            placeholder={user.nome}
            maxLength={50}
          />

          <TmpInput
            disabled={true}
            id="email"
            handleChange={handleValueChange}
            label="*E-mail"
            placeholder={user.email}
            maxLength={50}
          />

          <Select>
            <TmpSelect
              disabled={true}
              handleChange={(e) => handleSelectChange(e, "unit")}
              label="*Unidade"
              placeholder={chamado.unidade}
              listItems={[
                {
                  label: "BELA VISTA",
                },
                {
                  label: "BRASÍLIA",
                },
                {
                  label: "UNIDADE ITAIM",
                },
                {
                  label: "UNIDADE JARDINS",
                },
                {
                  label: "UNIDADE SAÚDE POP",
                },
                {
                  label: "UNIDADE SAÚDE 2",
                },
              ]}
            />

            <TmpSelect
              disabled={true}
              handleChange={(e) => handleSelectChange(e, "reason")}
              label="*Motivo Chamado"
              placeholder={chamado.motivo || "Sem motivo"}
              listItems={[
                {
                  label: "Nenhum",
                },
                {
                  label: "Licenças e Folgas",
                },
                {
                  label: "Benefícios",
                },
                {
                  label: "Férias",
                },
                {
                  label: "Controle de Ponto",
                },
                {
                  label:
                    "Solicitações Adicionais (PPP, RDT e atualizações de CTPS)",
                },
                {
                  label:
                    "Desenvolvimento Oganizacional / Cursos e Treinamentos",
                },
                {
                  label: "Remuneração",
                },
              ]}
            />

            <TmpSelect
              disabled={true}
              handleChange={(e) => handleSelectChange(e, "subreason")}
              label="*Submotivo Chamado"
              placeholder={chamado.submotivo || "Sem submotivo"}
              listItems={[
                {
                  label: "Nenhum",
                },
                {
                  label:
                    "Solicitações Adicionais (PPP, RDT e atualizações de CTPS)",
                },
                {
                  label: "Outros",
                },
              ]}
            />
            {values.reason && values.reason === "Férias" && (
              <>
                <TmpInput
                  type="date"
                  label="Período Férias Início"
                  mask="date"
                  id="startDate"
                  placeholder={
                    chamado.periodo_ferias?.split("-")[0] || "dd/mm/aaaa"
                  }
                  handleChange={handleValueChange}
                />

                <TmpInput
                  type="date"
                  label="Período Férias Final"
                  mask="date"
                  id="finalDate"
                  placeholder={
                    chamado.periodo_ferias?.split("-")[1] || "dd/mm/aaaa"
                  }
                  handleChange={handleValueChange}
                />

                <ContainerFerias>
                  <TmpSwitch
                    label="Adiantar 13º"
                    name="checkbox"
                    checked={chamado.adiantamento_13}
                    handleChange={(e) => handleCheckbox(e, setChecked)}
                  />

                  <TmpSwitch
                    label="Abono"
                    name="checkboxTwo"
                    checked={chamado.abono_ferias}
                    handleChange={(e) => handleCheckbox(e, setCheckedTwo)}
                  />
                </ContainerFerias>
              </>
            )}
          </Select>

          <TmpTextarea
            disabled={true}
            id="description"
            handleChange={handleValueChange}
            label="Descrição"
            placeholder={chamado.descricao || "Sem descrição"}
          />

          <TmpInput
            disabled={true}
            id="registration"
            handleChange={handleValueChange}
            label="*Matrículas dos colaboradores relacionados no chamado"
            placeholder={chamado.matricula_colaborador || "Ex: 11222, 22111..."}
            maxLength={50}
          />
          <ButtonForm>
            <TmpButton disabled={true} type="primary" size="sm">
              Enviar
            </TmpButton>
          </ButtonForm>
        </Content>
      </Form>

      <Footer>
        <p>© {ano()} Hospital Sírio-Libanês</p>
      </Footer>
    </Container>
  );
};

export default ListForms;
